import axios from "axios";

export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    getRegisteredClubMembersForNWP({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getRegisteredClubMembersForNWP.php", {
            auth: {
              username: data.satMemberNumber,
              password: data.password,
            },
            params: {
              nwpId: data.nwpId,
            },
          })
          .then((resp) => {
            //console.log('coachdata: ',JSON.stringify(resp));
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getClubMembers({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getClubMembersForCoach.php", {
            auth: {
              username: data.satMemberNumber,
              password: data.password,
            },
          })
          .then((resp) => {
            console.log(JSON.stringify(resp));
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    cancelRegistration({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/cancelRegistration.php", {
            auth: {
              username: data.satMemberNumber,
              password: data.password,
            },
            params: {
              kidId: data.kidId,
              nwpId: data.nwpId,
            },
          })
          .then((resp) => {
            console.log('coach resp: ', JSON.stringify(resp));
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getEventRegistrationForNWPExcel({ }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getEventRegistrationForNWPExcel.php", {
            auth: {
              username: data.satMemberNumber,
              password: data.password,
            },
            params: {
              nwpId: data.nwpId,
            },
            responseType: "arraybuffer",
          })
          .then((resp) => {
            const base64File = Buffer.from(resp.data, "binary").toString(
              "base64"
            );
            resolve(base64File);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    
  },
  mutations: {},
};
