import axios from "axios";

export default {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {
    getDocument({ commit }, file) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getDocument.php", {
            params: {
              document: file.fileId,
            },
            responseType: "arraybuffer",
          })
          .then((resp) => {
            const base64File = Buffer.from(resp.data, "binary").toString(
              "base64"
            );
            resolve(base64File);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getEventInfoDoc({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getEventInfoDoc.php", {
            params: {
              eventId: data.eventId,
              languageCode: data.languageCode,
            },
            responseType: "arraybuffer",
          })
          .then((resp) => {
            const base64File = Buffer.from(resp.data, "binary").toString(
              "base64"
            );
            resolve(base64File);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getEventResultDoc({ commit }, nwpId) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getOrganizerEventNwpHistories.php", {
            params: {
              nwpId: nwpId,
            },
            responseType: "arraybuffer",
          })
          .then((resp) => {
            const base64File = Buffer.from(resp.data, "binary").toString(
              "base64"
            );
            resolve(base64File);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    uploadLogoForNWPApplication({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/uploadLogoForNWPApplication.php", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    uploadLogoForNWP({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/uploadLogoForNWP.php", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteLogoForNWP({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/deleteLogoForNWP.php", {
            data: {
              fileId: id,
            }
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteLogoForNWPApplication({ commit }, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/deleteLogoForNWPApplication.php", {
            data: {
              fileId: id,
            }
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    uploadFileForNWPApplication({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/uploadFileForNWPApplication.php", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    uploadFileForNWP({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post("/uploadFileForNWP.php", data)
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteFileForNWP({ commit }, fileId) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/deleteFileForNWP.php", {
            data: {
              fileId: fileId,
            }
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    deleteFileForNWPApplication({ commit }, fileId) {
      return new Promise((resolve, reject) => {
        axios
          .delete("/deleteFileForNWPApplication.php", {
            data: {
              fileId: fileId,
            }
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {},
};
