import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    token: localStorage.getItem("token") || "",
    user: null,
  }),
  getters: {
    isAuthorized: (state) => {
      return !!state.token; // Returns boolean true/false
    },
    getToken: (state) => {
      return state.token;
    },
    getUser: (state) => {
      return state.user;
    },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .get("/auth.php", {
            auth: {
              username: data.username,
              password: data.password,
            },
          })
          .then((resp) => {
            const token = Buffer.from(
              `${data.username}:${data.password}`,
              "utf8"
            ).toString("base64");
            localStorage.setItem("token", token); // Speichere zugangs-token im local storage des browsers (cookie)
            axios.defaults.headers.common["Authorization"] = "Basic " + token; // Setze aktuelles token als standard für alle zukünftigen axios aufrufe
            commit("setUser", { user: resp.data });
            commit("setToken", { token: token });
            resolve(resp);
          })
          .catch((err) => {
            commit("resetUser");
            commit("resetToken");
            localStorage.removeItem("token"); // Entferne token aus local storage
            delete axios.defaults.headers.common["Authorization"]; // Entferne standard auth header aus allen axios aufrufen
            reject(err);
          });
      });
    },
    loginReset({ commit }, username) {
      return new Promise((resolve, reject) => {
        axios
          .get("/authReset.php", {
            params: {
              login: username,
              webPlattform: "NWPSPRINT"
            },
          })
          .then((resp) => {
            commit("resetToken");
            commit("resetUser");
            localStorage.removeItem("token"); // Entferne token aus local storage
            delete axios.defaults.headers.common["Authorization"]; // Entferne standard auth header aus allen axios aufrufen
            resolve(resp);
          })
          .catch((err) => {
            commit("resetToken");
            commit("resetUser");
            localStorage.removeItem("token"); // Entferne token aus local storage
            delete axios.defaults.headers.common["Authorization"]; // Entferne standard auth header aus allen axios aufrufen
            reject(err);
          });
      });
    },
    logout({ commit }) {
      commit("resetToken");
      commit("resetUser");
      localStorage.removeItem("token"); // Entferne token aus local storage
      delete axios.defaults.headers.common["Authorization"]; // Entferne standard auth header aus allen axios aufrufen
    },
    getEvents({}, data = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getOrganizerApplicationNWPData.php", {
            params: data
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getConfirmedEvents({}, data = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getOrganizerNWPData.php", {
            params: data
          })
          .then((resp) => {
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getEventRegisteredKids({}, data = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get("/GetOrganizerEventRegisteredKids.php", {
            params: data,
            responseType: "arraybuffer",
          })
          .then((resp) => {
            const base64File = Buffer.from(resp.data, "binary").toString(
              "base64"
            );
            resolve(base64File);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getOrganizerSprintEventExcel({}, data = {}) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getOrganizerSprintEventExcel.php", {
            params: data,
            responseType: "arraybuffer",
          })
          .then((resp) => {
            const base64File = Buffer.from(resp.data, "binary").toString(
              "base64"
            );
            resolve(base64File);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setToken(state, payload) {
      state.token = payload.token;
    },
    resetToken(state) {
      state.token = "";
    },
    setUser(state, payload) {
      state.user = payload.user;
    },
    resetUser(state) {
      state.user = null;
    },
  },
};
