import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import coach from "./modules/Coach";
import dateien from "./modules/Dateien";
import listsOfValue from "./modules/ListsOfValue";
import stadium from "./modules/Stadium";
import veranstalter from "./modules/Veranstalter";
import wettkampf from "./modules/Wettkampf";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    coach: coach,
    dateien: dateien,
    lov: listsOfValue,
    stadium: stadium,
    veranstalter: veranstalter,
    wettkampf: wettkampf,
  },
  plugins: [createPersistedState()],
});
