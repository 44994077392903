import Vue from "vue";
import VueRouter from "vue-router";
import i18n from "@/i18n";
import { localize } from "vee-validate";
import helpers from '@/helpers/helper';

// LayoutPages
import DefaultLayout from "@/layouts/DefaultLayout";

// Allgemeine Ansichten
const SeiteNichtGefunden = () =>
  import("@/views/AllgemeineAnsichten/SeiteNichtGefunden");
const SeitenUebersicht = () =>
  import("@/views/AllgemeineAnsichten/SeitenUebersicht");

// Event Erstellung
const EventErstellungEinstieg = () =>
  import("@/views/Veranstalter/EventRegistrierung/Einstieg");
  const EventErstellungFormular = () =>
  import("@/views/Veranstalter/EventRegistrierung/Formular");
  const EventErstellungAnmeldung = () =>
  import("@/views/Veranstalter/EventRegistrierung/Anmeldung");

// Veranstalter Seiten
const VeranstalterUebersicht = () => import("@/views/Veranstalter/LoginBereich/Uebersicht");
const VeranstalterDaten = () => import("@/views/Veranstalter/LoginBereich/VeranstalterDaten");
const EventUebersicht = () => import("@/views/Veranstalter/LoginBereich/WettkampfUebersicht");

// Resultat Anzeige
  const Resultatuebersicht = () => import("@/views/Resultate/ResultatUebersicht");
  const ResultatDetail = () => import("@/views/Resultate/ResultatDetail");

  // Bestenliste Anzeige
  const BestenlisteUebersicht = () => import("@/views/Bestenliste/BestenlisteUebersicht");

// Passwort vergessen
const PasswortReset = () => import("@/views/Veranstalter/EventRegistrierung/PasswortReset");

// Event Anzeige (Übersicht/Detail)
const Eventsuebersicht = () => import("@/views/Veranstaltungen/EventListe");
const DeatailSeite = () => import("@/views/Veranstaltungen/EventDetail");
const Test = () => import("@/views/Veranstaltungen/Test");

// Event Anmeldung (Einzel/Mehrfach)
const Einzelanmeldung = () => import("@/views/Veranstaltungen/Einzelanmeldung");
const Anmeldung = () => import("@/views/Anmeldung/Anmeldung");

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    redirect: process.env.VUE_APP_I18N_FALLBACK_LOCALE,
  },
  {
    path: "/:locale", // Request sprache als erster URL pfad Bsp. www.example.ch/de/ www.example.ch/fr/
    component: {
      template: "<router-view></router-view>",
    },
    beforeEnter: (to, from, next) => {
      // Hohle die angefrage Sprache anhand der url route /de/ oder /fr/
      const locale = to.params.locale;
      // Generiere einen array mit den unterstützten sprachen gem. VUE_APP_I18N_SUPPORTED_LOCALE im .env
      const supported_locales =
        process.env.VUE_APP_I18N_SUPPORTED_LOCALE.split(",");
      // Falls die angefragte Sprache nicht untersützt wird, setze die sprache auf "de"
      if (!supported_locales.includes(locale)) return next("de");
      if (i18n.locale !== locale) {
        i18n.locale = locale;
      }
      localize(i18n.locale); // Setze Sprache für Vee-Validate
      return next();
    },
    children: [
      {
        path: "",
        component: SeitenUebersicht
      },
      {
        path: "veranstalter",
        component: DefaultLayout,
        redirect: "/" + i18n.locale + "/veranstalter/event-erstellen",
        children: [
          {
            path: "event-erstellen",
            name: "EventErstellungEinstieg",
            component: EventErstellungEinstieg,
          },
          {
            path: "event-erstellen/formular",
            name: "EventErstellungFormular",
            component: EventErstellungFormular,
          },
          {
            path: "meine-events",
            name: "EventUebersicht",
            component: EventUebersicht,
          },
          {
            path: "uebersicht",
            component: VeranstalterUebersicht,
          },
          {
            path: "benutzerdaten",
            component: VeranstalterDaten,
          },
          {
            path: "login",
            component: EventErstellungAnmeldung,
          },
          {
            path: "login/reset",
            component: PasswortReset,
          },
        ],
      },
      {
        path: "veranstaltungen",
        component: DefaultLayout,
        redirect: "/" + i18n.locale + "/veranstaltungen",
        children: [
          {
            path: "",
            name: "EventUebersicht",
            component: Eventsuebersicht,
          },
          {
            path: ":eventId",
            name: "EventDetail",
            component: DeatailSeite,
            props: true
          },
          {
            path: ":eventId/einzelanmeldung",
            name: "EventEinzelanmeldung",
            component: Einzelanmeldung,
            props: true
          },
          {
            path: ":eventId/anmeldung",
            name: "EventEinzelanmeldung",
            component: Anmeldung,
            props: true
          },
          {
            path: "test",
            name: "TestSeite",
            component: Test,
          }
        ],
      },
      {
        path: "resultate",
        component: DefaultLayout,
        redirect: "/" + i18n.locale + "/resultate",
        children: [
          {
            path: "",
            name: "ResultatUebersicht",
            component: Resultatuebersicht,
          },
          {
            path: ":eventId",
            name: "ResultatDetail",
            component: ResultatDetail,
            props: true
          },
        ],
      },
      {
        path: "bestenlisten",
        component: DefaultLayout,
        redirect: "/" + i18n.locale + "/bestenlisten",
        children: [
          {
            path: "",
            name: "BestenUebersicht",
            component: BestenlisteUebersicht,
          },
        ],
      },
      {
        path: "*",
        component: SeiteNichtGefunden,
      }
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from, failure) => {
  if (!failure) helpers.scrollToTop();
})

export default router;