import axios from "axios";

export default {
  namespaced: true,
  state: () => ({
    Stadiums: [],
    StadiumsHomologiert: [],
  }),
  getters: {
    getStadium: (state) => (id) => {
      return state.Stadiums.find((Stadiums) => Stadiums.id === id);
    },
    getStadiumHomologiert: (state) => (id) => {
      return state.StadiumsHomologiert.find(
        (StadiumsHomologiert) => StadiumsHomologiert.id === id
      );
    },
  },
  actions: {
    getStadiums({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getStadium.php")
          .then((resp) => {
            const stadiumList = resp.data;
            commit("setStadiums", { list: stadiumList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    getStadiumsHomologiert({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/getStadium.php", {
            params: {
              homologiert: true,
            },
          })
          .then((resp) => {
            const stadiumList = resp.data;
            commit("setStadiumsHomologiert", { list: stadiumList });
            resolve(resp);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
  },
  mutations: {
    setStadiums(state, payload) {
      state.Stadiums = payload.list;
    },
    setStadiumsHomologiert(state, payload) {
      state.StadiumsHomologiert = payload.list;
    },
  },
};
