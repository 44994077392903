import Vue from "vue";
import App from "./App.vue";
import {
  ValidationObserver,
  ValidationProvider,
  localize,
  extend,
} from "vee-validate";
import de from "vee-validate/dist/locale/de.json";
import fr from "vee-validate/dist/locale/fr.json";
import * as rules from "vee-validate/dist/rules";
import router from "./router";
import store from "./store";
import Axios from "axios";
import { BootstrapVue } from "bootstrap-vue";
import "@/assets/scss/app.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import i18n from "./i18n";
import helpers from './helpers/helper'

library.add(fas);
Vue.component("font-awesome-icon", FontAwesomeIcon);

// Install VeeValidate rules and localization
Object.keys(rules).forEach((rule) => {
  extend(rule, rules[rule]);
});

// Add custom VeeValidate rule
extend("birthdate", value => { // Rule to validate birthdates allows dd.mm.yyyy or d.m.yyyy
  if (/^\d{1,2}\.\d{1,2}\.\d{4}$/g.test(value)) {
    return true;
  }

  return "{_field_} entspricht nicht dem korrekten Datumsformat";
});

// extend("phone", value => { // Rule to validate phonenumbers allows only numbers and no spaces
//   if (/^[0-9]*$/m.test(value)) {
//     return true;
//   }
//   return "{_field_} darf nur numerische Zeichen enthalten und muss ohne Leerzeichen eingegeben werden";
// });

// Texte für FR-Übersetzung von Vee-Validate anpassen
fr.messages.ext = "{_field_} doit être un fichier valide";

// Installiere notwendige Sprachpakete für Vee-Validate
localize({ de, fr });

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);

// Bootstrap Setup
Vue.use(BootstrapVue);

// Axios Setup
Vue.prototype.$http = Axios;

// Axios: Setze Auth token als default für alle api calls falls vorhanden im local storage
const token = localStorage.getItem("token");
if (token) {
  Vue.prototype.$http.defaults.headers.common["Authorization"] =
    "Basic " + token;
}

// Axios: Setze standard URL-Stamm für alle axios api calls
Axios.defaults.baseURL = process.env.NODE_ENV === "production" ? process.env.VUE_APP_API_URL_PROD : process.env.VUE_APP_API_URL_TEST;

Vue.config.productionTip = false;

// Setup global helper functions
const plugins = {
  install() {
      Vue.helpers = helpers;
      Vue.prototype.$helpers = helpers;
  }
}
Vue.use(plugins);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
